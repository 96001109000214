import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, COLORS, FONT_SIZE, FONT_WEIGHT, GLOBAL, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const linkStyles = (props) => css`
  align-items: center;
  display: flex;
  flex: none;
  text-decoration: none;
  width: ${props.fullWidth ? '100%' : 'auto'};
`;

const StyledA = styled.a`
  ${linkStyles}
`;

const StyledLink = styled.span`
  ${linkStyles}
`;

const StyledLinkWithIcon = styled.a`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.XX_SMALL};
  text-decoration: underline;

  svg {
    height: 18px;
    margin-left: ${SPACE.X0};
    vertical-align: middle;
    width: 18px;
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.X_SMALL};
  }
  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-size: ${FONT_SIZE.SMALL};
  }
`;

const buttonStyles = styled(Button)`
  &.Mui-focusVisible {
    outline: 2px solid ${COLORS.BRAND_BLUE_EIGHT};
    outline-offset: 2px;
  }

  && {
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    font-family: ${GLOBAL.BRAND_FONT_FAMILY};
    font-size: 16px;
    font-weight: ${FONT_WEIGHT.BOLD};
    line-height: 1;
    max-width: 100%;
    padding: ${(props) => (props.fullWidth ? `12px ${SPACE.X0}` : `12px ${SPACE.X2}`)};
    text-transform: none;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

    .MuiButton-label .MuiCircularProgress-colorPrimary {
      color: ${COLORS.GREY_ELEVEN};
    }

    @media (min-width: ${BREAKPOINTS.MD}px) {
      font-size: 18px;
    }

    @media (min-width: ${BREAKPOINTS.LG}px) {
      font-size: 20px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .MuiButton-startIcon {
      margin-right: 10px;
    }
  }
`;

const PRIMARY_BUTTON_STYLES = `
  background-color: ${COLORS.BRAND_YELLOW_SEVEN};
  border-color: ${COLORS.BRAND_YELLOW_SEVEN};
  color: ${COLORS.GREY_ELEVEN};

  &:hover {
    background-color: ${COLORS.BRAND_YELLOW_EIGHT};
    border-color: ${COLORS.BRAND_YELLOW_EIGHT};
    box-shadow: none;
  }

  &:active {
    background-color: ${COLORS.BRAND_YELLOW_NINE};
    border-color: ${COLORS.BRAND_YELLOW_NINE};
    box-shadow: none;
  }
`;

const StyledPrimaryButton = styled(buttonStyles)`
  &.MuiButton-root {
    ${PRIMARY_BUTTON_STYLES};
  }

  &.MuiButton-root.Mui-disabled {
    background-color: ${COLORS.GREY_FIVE};
    border-color: ${COLORS.GREY_FIVE};
    color: ${COLORS.GREY_NINE};
  }
`;

const SECONDARY_BUTTON_STYLES = `
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.BLACK};
  color: ${COLORS.BLACK};
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  font-weight: ${FONT_WEIGHT.REGULAR};

  &:hover {
    background-color: ${COLORS.BRAND_YELLOW_SEVEN};
    box-shadow: none;
  }

  &:active {
    background-color: ${COLORS.BRAND_YELLOW_NINE};
    box-shadow: none;
  }
`;

const StyledSecondaryButton = styled(buttonStyles)`
  &.MuiButton-root {
    ${SECONDARY_BUTTON_STYLES}
  }

  &.MuiButton-root.Mui-disabled {
    border-color: ${COLORS.GREY_SIX};
    color: ${COLORS.GREY_SIX};
  }
`;

const StyledTertiaryButton = styled(buttonStyles, { shouldForwardProp })`
  &.MuiButton-root {
    background-color: transparent;
    border-color: transparent;
    color: ${(props) => (props.textColor ? props.textColor : COLORS.BRAND_BLUE_SIX)};
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};

    &:hover {
      background-color: ${COLORS.BRAND_BLUE_ONE};
      border-color: ${COLORS.BRAND_BLUE_ONE};
      box-shadow: none;
      text-decoration: underline;
    }

    .MuiButton-label .MuiCircularProgress-colorPrimary {
      color: ${COLORS.BRAND_BLUE_SEVEN};
    }
  }
`;

const StyledCompareButton = styled(buttonStyles)`
  &.MuiButton-root {
    ${(props) => (props.count ? PRIMARY_BUTTON_STYLES : SECONDARY_BUTTON_STYLES)};

    padding: 4px 8px;
    min-width: auto;

    @media (min-width: ${BREAKPOINTS.MD}px) {
      padding: 8px 20px;
    }

    @media (min-width: ${BREAKPOINTS.LG}px) {
      padding: 16px 24px;
    }
  }
  && .MuiButton-startIcon {
    margin-left: 0px;
  }
`;

export {
  StyledA,
  StyledLink,
  StyledLinkWithIcon,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledTertiaryButton,
  StyledCompareButton,
};
