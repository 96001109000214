import { COLORS, FONT_SIZE, BREAKPOINTS } from '../../constants';
import muiTheme from '../../constants/mui-theme';

const formTheme = {
  palette: {
    primary: {
      main: muiTheme.palette.primary.main,
    },
    secondary: {
      main: muiTheme.palette.secondary.main,
      dark: muiTheme.palette.secondary.dark,
    },
    background: {
      main: COLORS,
    },
    error: {
      main: muiTheme.palette.error.main,
    },
  },
  typography: {
    fontSize: { xs: FONT_SIZE.X_SMALL, md: FONT_SIZE.MEDIUM },
    icon: {
      fontSize: { xs: FONT_SIZE.XX_LARGE, md: FONT_SIZE.LARGE },
    },
    label: {
      fontSize: { xs: FONT_SIZE.SMALL, md: FONT_SIZE.LARGE },
      p: {
        fontSize: { xs: FONT_SIZE.X_SMALL, md: FONT_SIZE.MEDIUM },
      },
    },
    menu: {
      fontSize: FONT_SIZE.MEDIUM,
    },
    helperText: {
      fontSize: FONT_SIZE.X_SMALL,
    },
  },
};

const fieldStyles = {
  root: `
    border-radius: 0;
    font-size: ${formTheme.typography.fontSize.xs};
    height: 40px;
    line-height: 18px;

    @media (min-width: ${BREAKPOINTS.MD}px) {
      font-size: ${formTheme.typography.fontSize.md};
      height: auto;
      line-height: 22px;
    };

    &.MuiInputBase-multiline {
      height: auto;
    }
  `,

  helperText: `
    font-size: ${formTheme.typography.helperText.fontSize};
  `,

  outline: `
    .MuiOutlinedInput-root.Mui-focused.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }

    & fieldset {
      border-color:${COLORS.BRAND_BLUE_SIX};
    }
  `,

  label: `
    .MuiInputLabel-outlined {
      transform: translate(14px, 10px) scale(1);

      @media (min-width: ${BREAKPOINTS.MD}px) {
        transform: translate(14px, 14px) scale(1);
      };
    }
  `,
};

export { fieldStyles, formTheme };
