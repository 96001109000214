import styled from '@emotion/styled';

import { COLORS, BREAKPOINTS, SPACE, FONT_SIZE, FONT_WEIGHT, RATERS } from '../../constants';
import { Flex } from '../grid';
import { shouldForwardProp } from '../../utils/should-forward-prop';

import { STAR_PLACEMENT } from './constants';

const LOGO_SIZE = {
  [RATERS.ANCAP]: {
    [BREAKPOINTS.XS]: '115px',
    [BREAKPOINTS.SM]: '104px',
    [BREAKPOINTS.MD]: '138px',
    [BREAKPOINTS.LG]: '190px',
  },
  [RATERS.UCSR]: {
    [BREAKPOINTS.XS]: '209px',
    [BREAKPOINTS.SM]: '147px',
    [BREAKPOINTS.MD]: '184px',
    [BREAKPOINTS.LG]: '294px',
  },
};

const STAR_SIZE = {
  [STAR_PLACEMENT.SAFETY_ROW]: {
    [BREAKPOINTS.XS]: '14px',
    [BREAKPOINTS.SM]: '15px',
    [BREAKPOINTS.MD]: '15px',
    [BREAKPOINTS.LG]: '22px',
  },
  [STAR_PLACEMENT.CAR_CARD]: {
    [BREAKPOINTS.XS]: '26px',
    [BREAKPOINTS.SM]: '26px',
    [BREAKPOINTS.MD]: '26px',
    [BREAKPOINTS.LG]: '20px',
    [BREAKPOINTS.XL]: '26px',
  },
  [STAR_PLACEMENT.SCORECARD]: {
    [BREAKPOINTS.XS]: '26px',
    [BREAKPOINTS.SM]: '20px',
    [BREAKPOINTS.MD]: '26px',
    [BREAKPOINTS.LG]: '32px',
  },
  [STAR_PLACEMENT.COMPARISON_TILE]: {
    [BREAKPOINTS.XS]: '12px',
    [BREAKPOINTS.SM]: '12px',
    [BREAKPOINTS.MD]: '18px',
    [BREAKPOINTS.LG]: '20px',
  },
  [STAR_PLACEMENT.COMPARISON_CARD]: {
    [BREAKPOINTS.XS]: '16px',
    [BREAKPOINTS.SM]: '16px',
    [BREAKPOINTS.MD]: '26px',
    [BREAKPOINTS.LG]: '26px',
    [BREAKPOINTS.XL]: '26px',
  },
};

const LogoImageWrapper = styled('div', shouldForwardProp)`
  width: ${({ ratingBy }) => LOGO_SIZE[ratingBy][BREAKPOINTS.XS]};

  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: ${({ ratingBy }) => LOGO_SIZE[ratingBy][BREAKPOINTS.SM]};
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    width: ${({ ratingBy }) => LOGO_SIZE[ratingBy][BREAKPOINTS.MD]};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: ${({ ratingBy }) => LOGO_SIZE[ratingBy][BREAKPOINTS.LG]};
  }
  max-width: 80%;
  height: auto;
  margin-bottom: ${SPACE.X0};
`;

const Stars = styled.div`
  width: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.XS]};
  height: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.XS]};

  & > * {
    vertical-align: top;
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.SM]};
    height: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.SM]};
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    width: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.MD]};
    height: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.MD]};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.LG]};
    height: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.LG]};
  }

  @media (min-width: ${BREAKPOINTS.XL}px) {
    width: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.XL]};
    height: ${({ placement }) => STAR_SIZE[placement][BREAKPOINTS.XL]};
  }
`;

const StarRatingWrapper = styled(Flex, shouldForwardProp)`
  flex-direction: ${({ ratingBy }) => (ratingBy === RATERS.ANCAP ? 'row' : 'column-reverse')};
  align-items: stretch;
`;

const AncapTestYearLabel = styled.span`
  font-size: ${FONT_SIZE.XXX_SMALL};
  font-weight: ${FONT_WEIGHT.REGULAR};
  line-height: 100%;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.XX_SMALL};
  }
`;

const AncapTestYear = styled.span`
  font-size: ${FONT_SIZE.XXX_SMALL};
  font-weight: ${FONT_WEIGHT.BOLD};
  line-height: 100%;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-size: ${FONT_SIZE.XX_SMALL};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.MEDIUM};
  }
`;

const OverallSafetyLabel = styled.span`
  font-size: ${FONT_SIZE.MEDIUM};
  font-weight: ${FONT_WEIGHT.REGULAR};
  line-height: 100%;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.SMALL};
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-size: ${FONT_SIZE.MEDIUM};
  }
`;

const RatingLabelWrapper = styled(Flex, shouldForwardProp)`
  flex-direction: column;
  justify-content: center;
  color: ${({ ratingBy }) => (ratingBy === RATERS.UCSR ? COLORS.BRAND_BLUE_TWELVE : COLORS.BLACK)};
  background-color: ${({ ratingBy }) => (ratingBy === RATERS.UCSR ? COLORS.WHITE : COLORS.BRAND_YELLOW_SEVEN)};
  padding: 0 0.25rem;
  text-align: center;
  text-transform: uppercase;
`;

const TestLabel = styled.span`
  font-size: ${FONT_SIZE.XX_SMALL};
`;

const StarsWrapper = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid ${({ showBorder }) => (showBorder ? COLORS.BRAND_YELLOW_SEVEN : COLORS.WHITE)};
  padding: ${({ noPadding, placement }) => {
    if (noPadding) return null;
    return placement === STAR_PLACEMENT.COMPARISON_TILE ? '0.25rem' : `${SPACE.X0} ${SPACE.X1}`;
  }};
`;

export {
  LogoImageWrapper,
  StarRatingWrapper,
  TestLabel,
  RatingLabelWrapper,
  AncapTestYear,
  AncapTestYearLabel,
  OverallSafetyLabel,
  Stars,
  StarsWrapper,
};
