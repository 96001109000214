import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { COLORS, LOCALSTORAGE_KEYS, URLS, LINKS } from '../../constants';
import Footer from '../footer';
import Header from '../header';
import { iconNames } from '../icons';
import MiniCartWrapper from '../mini-cart-wrapper';
import Loading from '../loading';
import Seo from '../../utils/seo/component';
import { hydrateVehicleIdsStateFromObject } from '../../utils/hydrate-vehicle-ids-state';

const Wrapper = styled.div`
  background-color: ${COLORS.GREY_ONE};
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const titleSecondary = 'More about';

const footerLinks = {
  termsAndPrivacyLinks: LINKS.TERMS_AND_PRIVACY,
  socialLinks: [
    { iconName: iconNames.facebook, url: URLS.FACEBOOK },
    { iconName: iconNames.instagram, url: URLS.INSTAGRAM },
    { iconName: iconNames.twitter, url: URLS.TWITTER },
  ],
  pageLinks: [...LINKS.PRIMARY, ...LINKS.SECONDARY],
};

const hydrateCompareVehicleIds = (compareVehicleIds, actions) => {
  if (!compareVehicleIds || !compareVehicleIds.length) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const storageItem = localStorage.getItem(LOCALSTORAGE_KEYS.COMPARE);
      const vehicleIds = storageItem ? JSON.parse(storageItem) : [];
      hydrateVehicleIdsStateFromObject(vehicleIds, actions);
    }
  }
};

const Layout = ({
  actions,
  children,
  className = '',
  compareVehicleIds = [],
  filterActions,
  isHomePage = false,
  isOpen = false,
}) => {
  const { closeMiniCart, openMiniCart, removeFromCompare } = actions;
  const numOfVehiclesToCompare = _.size(compareVehicleIds);

  hydrateCompareVehicleIds(compareVehicleIds, actions);

  return (
    <Wrapper className={className}>
      <Seo />
      <Loading />
      <MiniCartWrapper {...{ compareVehicleIds, filterActions, isOpen, removeFromCompare }} onClose={closeMiniCart} />
      <Header
        {...{ isHomePage }}
        compareButtonProps={{ count: numOfVehiclesToCompare, onOpenComparison: openMiniCart }}
        primaryNavProps={{ links: LINKS.PRIMARY }}
        secondaryNavProps={{ links: LINKS.SECONDARY, title: titleSecondary }}
        socialLinksProps={{ socialIcons: footerLinks.socialLinks, tagline: 'Join the conversation' }}
      />
      {children}
      <Footer {...footerLinks} />
    </Wrapper>
  );
};

Layout.propTypes = {
  actions: PropTypes.shape({
    addToCompare: PropTypes.func.isRequired,
    closeMiniCart: PropTypes.func.isRequired,
    openMiniCart: PropTypes.func.isRequired,
    removeFromCompare: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  filterActions: PropTypes.shape({}).isRequired,
  isHomePage: PropTypes.bool,
  isOpen: PropTypes.bool,
  compareVehicleIds: PropTypes.arrayOf(PropTypes.string),
};

const mapState = (state) => ({
  compareVehicleIds: state.compare.compareVehicleIds,
  isOpen: state.ui.global.isMiniCartOpen,
});

const mapDispatch = (dispatch) => ({
  actions: {
    addToCompare: dispatch.compare.addToCompare,
    closeMiniCart: dispatch.ui.closeMiniCart,
    openMiniCart: dispatch.ui.openMiniCart,
    removeFromCompare: dispatch.compare.removeFromCompare,
  },
  filterActions: dispatch.filters,
});

export default connect(mapState, mapDispatch)(Layout);
