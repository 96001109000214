import _ from 'lodash';

const objectToArrayWithKeys = (object) =>
  _.chain(object)
    // the SEOmatic API gives us back arrays instead of objects if there's not data for some reason.
    .pickBy((val) => !!val && !Array.isArray(val) && _.size(val) > 0)
    .map((data, key) => ({ key, data }))
    .value();

export default objectToArrayWithKeys;
