import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Link from 'next/link';

import Icon from '../icons';
import { A, ExtraSmall, Small } from '../type';
import { BREAKPOINTS, FONT_SIZE, GLOBAL, COLORS, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

const ContentWrapper = styled.div`
  background-color: ${COLORS.BLACK};
`;

const LogoListItem = styled.li`
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  display: flex;
  padding: 0;
  column-count: 3;
  list-style-type: none;
  width: 100px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    display: block;
  }
`;

const ListLink = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  column-count: 2;
  list-style-type: none;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    display: block;
  }
`;

const Svg = styled.svg`
  height: 100%;
  width: 100%;
  padding-right: ${SPACE.X1};
`;

const LinksLabel = styled(Small)`
  color: ${COLORS.WHITE};
  display: flex;
  justify-content: center;
  line-height: 22px;
  margin-bottom: ${SPACE.X1};
  margin-top: ${SPACE.X2};
  min-height: 22px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    text-align: left;
    justify-content: inherit;
  }
`;

const SocialLink = styled.li``;

const FocusA = styled(A)`
  &:focus {
    outline: ${COLORS.BRAND_BLUE_FIVE} solid 2px;
  }
  &:hover {
    outline: ${COLORS.BRAND_BLUE_FIVE} solid 2px;
  }
`;

const TermItem = styled.li`
  display: inline-block;
`;

const LinkStyle = css`
  && {
    color: ${COLORS.WHITE};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const FooterLinks = styled(ExtraSmall)`
  ${LinkStyle}
  && {
    color: ${COLORS.WHITE};
    margin-bottom: ${SPACE.X2};
    text-align: center;
    @media (min-width: ${BREAKPOINTS.SM}px) {
      margin-bottom: ${SPACE.X1};
      text-align: left;
    }
  }
`;

const FooterLink = styled('li')`
  ${LinkStyle}
  && {
    font-family: ${GLOBAL.BRAND_FONT_FAMILY};
    font-size: ${FONT_SIZE.LARGE};
    margin-bottom: ${SPACE.X1};

    @media (min-width: ${BREAKPOINTS.LG}px) {
      font-size: ${FONT_SIZE.X_LARGE};
    }
  }
`;

const StyledLink = styled(Link)`
  &:focus {
    outline: ${COLORS.BRAND_BLUE_FIVE} solid 2px;
  }

  &:hover {
    outline: ${COLORS.BRAND_BLUE_FIVE} solid 2px;
  }
`;

const TermsLink = styled(A)`
  ${LinkStyle}
  && {
    color: ${COLORS.WHITE};
  }
`;

const Logos = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: ${SPACE.X2} auto;
  width: 208px;
  justify-content: center;
  padding: 0;
  list-style-type: none;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: auto;
    justify-content: flex-start;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    flex-wrap: nowrap;
    width: auto;
    justify-content: inherit;
  }
`;

const LogoWrapper = styled(A, { shouldForwardProp })`
  display: inline-block;
  margin: 0 ${SPACE.X0};
  width: ${({ isShortened }) => (isShortened ? '71px' : '89px')};

  && {
    :focus {
      outline: ${COLORS.BRAND_BLUE_FIVE} solid 2px;
    }
  }

  @media (min-width: ${BREAKPOINTS.SM}px) {
    margin: 0 ${SPACE.X1} 0 0;
    width: ${({ isShortened }) => (isShortened ? '42px' : '80px')};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    margin: 0 ${SPACE.X1} 0 0;
    width: ${({ isShortened }) => (isShortened ? '62px' : '100px')};
  }
`;

const LinkWrapper = styled.div`
  margin-bottom: ${SPACE.X1};
`;

const Pipe = styled.span`
  padding-left: ${SPACE.X0};
  padding-right: ${SPACE.X0};
`;

const StyledIcon = styled(Icon)`
  fill: ${COLORS.GREY_ONE};
  height: 18px;
  width: 18px;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    height: 14px;
    width: 14px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    height: 22px;
    width: 22px;
  }
`;

export {
  ContentWrapper,
  FooterLink,
  FooterLinks,
  FooterWrapper,
  FocusA,
  LinksLabel,
  LinkWrapper,
  List,
  ListLink,
  Logos,
  LogoListItem,
  LogoWrapper,
  Pipe,
  SocialLink,
  StyledIcon,
  Svg,
  TermItem,
  TermsLink,
  StyledLink,
};
