import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { StyledBreadcrumbs, StyledCrumbLink, CrumbSeparator, Bold } from './styles';

const Breadcrumbs = ({ entry }) => {
  if (_.isNil(entry) || _.isEmpty(entry.ancestors)) {
    return null;
  }

  return (
    <StyledBreadcrumbs>
      {_.map(entry.ancestors, ({ title, url }, index) => {
        const isRootEntry = index === 0;
        return (
          <li key={title}>
            <StyledCrumbLink href={url}>{isRootEntry ? <Bold>{title}</Bold> : title}</StyledCrumbLink>
            <CrumbSeparator>{isRootEntry ? <Bold>/</Bold> : '/'}</CrumbSeparator>
          </li>
        );
      })}
      <li>{entry.title}</li>
    </StyledBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  entry: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default Breadcrumbs;
