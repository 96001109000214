import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';

import { Panel } from '../panel';
import { IconButton } from '../icon-button';
import NavLinks from '../nav-links';
import SocialLinks from '../social-links';
import { BREAKPOINTS, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const StyledNavigation = styled.nav`
  margin: ${SPACE.X4} 0 ${SPACE.X4} 0;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    flex: 1;
    margin: 0;
  }
`;

const StyledNavLinks = styled(NavLinks, { shouldForwardProp })`
  margin: 0 0 ${(props) => (props.isSecondary ? `${SPACE.X1}` : `${SPACE.X2}`)} 0;
`;

const StyledSocialLinks = styled(SocialLinks)`
  margin: calc(${SPACE.X3} + ${SPACE.X4}) 0;
  display: flex;
  align-items: center;
`;

const StyledMobileWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.MD}px) {
    display: none;
  }
`;

const StyledDesktopWrapper = styled.span`
  flex: 1;
  display: none;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    display: flex;
  }
`;

const Navigation = ({
  primaryNavProps,
  secondaryNavProps = { links: [], navTitle: '' },
  socialLinksProps = { socialIcons: [], tagline: '' },
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const { socialIcons, tagline } = socialLinksProps;

  return (
    <>
      <StyledMobileWrapper>
        <IconButton
          ariaLabel="open mobile navigation"
          className="hamburger-icon-button"
          edge="start"
          iconName="hamburger"
          name="Show navigation"
          onClick={() => setIsMobileNavOpen(true)}
          size="large"
        />
        <Hidden mdUp>
          <Panel ariaLabel="mobile-navigation" isOpen={isMobileNavOpen} onClose={() => setIsMobileNavOpen(false)}>
            <Box p={SPACE.X2}>
              <StyledNavigation>
                <StyledNavLinks navigationProps={primaryNavProps} />
              </StyledNavigation>
              {secondaryNavProps && (
                <StyledNavigation>
                  <StyledNavLinks navigationProps={secondaryNavProps} isSecondary />
                </StyledNavigation>
              )}
              {socialLinksProps && <StyledSocialLinks {...{ socialIcons, tagline }} />}
            </Box>
          </Panel>
        </Hidden>
      </StyledMobileWrapper>
      <StyledDesktopWrapper>
        <StyledNavigation>
          <NavLinks navigationProps={primaryNavProps} />
        </StyledNavigation>
      </StyledDesktopWrapper>
    </>
  );
};

export default Navigation;

Navigation.propTypes = {
  primaryNavProps: PropTypes.shape({
    links: PropTypes.array,
  }).isRequired,
  secondaryNavProps: PropTypes.shape({
    links: PropTypes.array,
    navTitle: PropTypes.string,
  }),
  socialLinksProps: PropTypes.shape({
    socialIcons: PropTypes.array,
    tagline: PropTypes.string,
  }),
};
