import styled from '@emotion/styled';

import { H3 } from '../type';
import { BREAKPOINTS, SPACE } from '../../constants';
import { Container } from '../grid';

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    padding-bottom: 48px;
    padding-top: 48px;

    @media (min-width: ${BREAKPOINTS.LG}) {
      padding-bottom: ${SPACE.X3};
      padding-top: ${SPACE.X3};
    }
  }
`;

const StyledH3 = styled(H3)`
  margin-bottom: calc(${SPACE.X1} + ${SPACE.X0});
`;

export { StyledContainer, StyledH3 };
