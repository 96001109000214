import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container, Col, Row } from '../grid';
import RichText from '../rich-text';
import { H3 } from '../type';
import { BREAKPOINTS, SPACE } from '../../constants';
import AccordionFaq from '../accordion/accordionFaq';

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    padding-bottom: ${SPACE.X2};
    padding-top: ${SPACE.X2};

    @media (min-width: ${BREAKPOINTS.LG}) {
      padding-bottom: ${SPACE.X3};
      padding-top: ${SPACE.X3};
    }
  }
`;

const StyledCol = styled(Col)`
  text-align: center;
`;

const StyledH3 = styled(H3)`
  margin-bottom: calc(${SPACE.X1} + ${SPACE.X0});
`;

const StyledRichText = styled(RichText)`
  margin-bottom: ${SPACE.X2};
`;

const colWidth = { xs: 12, lg: 10, xl: 8 };
const colOffset = [0, 0, 0, 1 / 12, 2 / 12];

const AccordionBlock = ({ heading = null, body = null, accordionItems = [] }) => {
  return (
    <StyledContainer>
      <Row>
        <StyledCol width={colWidth} offset={colOffset}>
          {heading && <StyledH3 as="h2">{heading}</StyledH3>}
          {body && <StyledRichText>{body}</StyledRichText>}
        </StyledCol>
        <Col width={colWidth} offset={colOffset}>
          <AccordionFaq items={accordionItems} />
        </Col>
      </Row>
    </StyledContainer>
  );
};

AccordionBlock.query = `
  ... on contentMatrix_accordionBlock_BlockType {
    id
    typeHandle
    heading
    body
    accordionItems {
      ... on accordionItems_accordionItems_Entry {
        heading
        body
      }
    }
  }
`;

AccordionBlock.propTypes = {
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
  heading: PropTypes.string,
  body: PropTypes.string,
};

export default AccordionBlock;
