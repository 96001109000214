import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const basePropTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      typeHandle: PropTypes.string.isRequired,
    })
  ),
  breadcrumbs: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
};

const Placeholder = () => <div>Coming soon!</div>;

const BaseContentMatrix = ({ blocks = [], breadcrumbs = {}, elementMapping }) => {
  if (_.isEmpty(blocks)) {
    return null;
  }

  return (
    <>
      {_.map(blocks, (block) => {
        let Block = elementMapping[block.typeHandle];

        if (!Block) {
          Block = Placeholder;
        }
        return <Block key={block.id} {...{ ...block, breadcrumbs }} />;
      })}
    </>
  );
};

BaseContentMatrix.propTypes = {
  blocks: basePropTypes.blocks,
  breadcrumbs: basePropTypes.breadcrumbs,
  elementMapping: PropTypes.shape({}).isRequired,
};

export default BaseContentMatrix;
export { Placeholder, basePropTypes };
