import { get } from 'lodash';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Image from 'next/image';

import { PrimaryButton } from '../button';
import RichText from '../rich-text';
import { H3, SectionHeadingStyles } from '../type';
import { BREAKPOINTS, SPACE } from '../../constants';
import { useGutterSpacing } from '../../hooks/use-gutter-spacing';
import { ContentBlockWrapper } from '../content-block-wrapper';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const Video = ({ captions, src, mimeType, poster }) => (
  <video crossOrigin="anonymous" autoPlay loop muted playsInline poster={poster} width="100%" controls>
    <track label="English" kind="captions" srcLang="en" src={get(captions, '[0].url')} default />
    <source src={src} type={mimeType} />
  </video>
);

const StyledH3 = styled(H3)`
  margin-bottom: ${SPACE.X1};
`;

const StyledRichText = styled(RichText)`
  ${SectionHeadingStyles};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  &.MuiButton-root {
    margin-top: ${SPACE.X2};
  }
`;

const StyledBox = styled(Box)`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: unset;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const StyledGrid = styled(Grid, { shouldForwardProp })(({ isReverse, useBackgroundImage }) => {
  const mediaQueryName = `@media (min-width: ${BREAKPOINTS.LG}px)`;
  return {
    '&.MuiGrid-root': {
      justifyContent: isReverse ? 'flex-end' : 'flex-start',
      '& > .MuiGrid-item': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .image': {
        order: isReverse && useBackgroundImage ? 2 : 1,
      },
      '& .content': {
        order: isReverse && useBackgroundImage ? 1 : 2,
      },
      [mediaQueryName]: {
        '& .image': {
          order: isReverse ? 2 : 1,
        },
        '& .content': {
          order: isReverse ? 1 : 2,
        },
      },
    },
  };
});

const ContentMediaBlock = ({
  useBackgroundImage = false,
  children = null,
  media = {
    kind: 'image',
    src: '/images/content-media-block-placeholder.jpg',
    alt: 'content image block - default image',
  },
  heading = null,
  body = null,
  callToAction = null,
  isReverse = false,
}) => {
  const lgUp = useMediaQuery(`(min-width: ${BREAKPOINTS.LG}px)`);

  return (
    <ContentBlockWrapper url={media.kind === 'image' ? media.src : null} useBackgroundImage={useBackgroundImage}>
      <Container maxWidth="xl">
        <Box pt={lgUp ? SPACE.X3 : SPACE.X2} pb={lgUp ? SPACE.X3 : '48px'}>
          <StyledGrid
            container
            spacing={useGutterSpacing()}
            isReverse={isReverse}
            useBackgroundImage={useBackgroundImage}
          >
            <Grid item xs={useBackgroundImage ? 6 : 12} lg={6} className="image">
              {!useBackgroundImage && (
                <Box mb={lgUp ? 0 : SPACE.X2}>
                  {media.kind === 'image' ? (
                    <StyledImage {...media} width={600} height={450} loading="lazy" />
                  ) : (
                    <Video {...media} />
                  )}
                </Box>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={useBackgroundImage ? 6 : 12}
              lg={5}
              className="content"
            >
              <StyledBox
                justifyContent="flex-start"
                ml={!isReverse && lgUp ? SPACE.X2 : 0}
                mr={isReverse && lgUp ? SPACE.X2 : 0}
              >
                {heading && <StyledH3 as="h2">{heading}</StyledH3>}
                {body && <StyledRichText {...{ callToAction }}>{body}</StyledRichText>}
                {children}
              </StyledBox>
              <StyledBox ml={!isReverse && lgUp ? SPACE.X2 : 0} mr={isReverse && lgUp ? SPACE.X2 : 'auto'}>
                {callToAction && (
                  <StyledPrimaryButton fullWidth={!lgUp} href={callToAction.href}>
                    {callToAction.label}
                  </StyledPrimaryButton>
                )}
              </StyledBox>
            </Grid>
          </StyledGrid>
        </Box>
      </Container>
    </ContentBlockWrapper>
  );
};

ContentMediaBlock.query = `
  ... on contentMatrix_contentMediaBlock_BlockType {
    id
    typeHandle
    heading
    body
    useBackgroundImage
    isReverse
    ctaButton {
      ...on ctaButton_BlockType {
        pdfDocument {
          url
        }
        isEnabled
        label
        externalUrl
        internalEntry {
          uri
          slug
        }
      }
    }
    media {
      kind
      mimeType
      title
      url
      ...on contentAssets_Asset {
        posterImage {
          url
          title
        }
        captions {
          url
        }
      }
    }
  }
  `;

ContentMediaBlock.propTypes = {
  useBackgroundImage: PropTypes.bool,
  children: PropTypes.node,
  media: PropTypes.shape({
    kind: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  heading: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
    to: PropTypes.string,
  }),
  isReverse: PropTypes.bool,
};

export default ContentMediaBlock;
