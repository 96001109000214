import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledCardImage = styled.img`
  height: auto;
  width: 100%;
`;

const CardImage = ({ src = '/images/car-image-not-found.svg', alt }) => <StyledCardImage {...{ src, alt }} />;

export default CardImage;

CardImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
};
