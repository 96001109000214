import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H5 } from '../type';
import RichText from '../rich-text';

import { useAccordion } from './hooks';
import { DividerFaq, StyledAccordionDetails, StyledAccordionFaq, StyledAccordionSummaryFaq } from './styles';

const AccordionFaq = ({ items = [] }) => {
  const [openAccordions, handleChange] = useAccordion();
  const textPadding = { xs: 4.5, sm: 9 };

  return (
    <>
      {_.map(items, (item, accordionId) => {
        return (
          <Box mb={{ xs: 3, sm: 6 }} key={accordionId}>
            <StyledAccordionFaq expanded={!!openAccordions[accordionId]} onChange={() => handleChange(accordionId)}>
              <StyledAccordionSummaryFaq expandIcon={<ExpandMoreIcon />}>
                <Box p={textPadding}>
                  <H5 as="h3">{item.heading}</H5>
                </Box>
              </StyledAccordionSummaryFaq>
              <DividerFaq />
              <StyledAccordionDetails>
                <Box p={textPadding}>
                  <RichText noSpacing>{item.body}</RichText>
                </Box>
              </StyledAccordionDetails>
            </StyledAccordionFaq>
          </Box>
        );
      })}
    </>
  );
};

AccordionFaq.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
};

export default AccordionFaq;
