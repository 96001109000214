import _ from 'lodash';

import { BODY_STYLE_KEYS, LIFESTYLE_PREFERENCES } from '../constants';
import { priceRangeSliderMarks } from '../components/discovery-questions-input/utils/price-range-slider-marks';
import { yearSliderMarks } from '../components/discovery-filter/constants';

const hydrateFiltersStateFromUrl = (queryParams, filterActions) => {
  const searchParams = new URLSearchParams(queryParams);
  const year = searchParams.get('year');
  const price = searchParams.get('price');
  const safetyFeatures = searchParams.get('safetyFeatures');
  const bodyStyles = searchParams.get('bodyStyle');
  const makes = searchParams.get('makes');
  const starRatings = searchParams.get('starRating');

  const {
    addLifestyle,
    updateMakes,
    updateSafetyFeatures,
    updateSelectedBodyStyles,
    updateSelectedPriceRange,
    updateStarRatings,
    updateSelectedYearRange,
    updateUiSelectedYearRange,
    updateUiSelectedPriceRange,
  } = filterActions;

  if (!queryParams) {
    return;
  }

  updateSelectedPriceRange(
    price ? price.split(',').map(Number) : [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value]
  );

  updateUiSelectedPriceRange(
    price ? price.split(',').map(Number) : [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value]
  );

  updateMakes(makes ? makes.split(',').map(String) : []);

  updateSafetyFeatures(safetyFeatures ? safetyFeatures.split(',').map((feature) => feature.toUpperCase()) : []);

  updateStarRatings(starRatings ? _.map(starRatings.split(','), (stars) => _.startCase(stars)) : []);

  updateSelectedBodyStyles(
    bodyStyles && !_.includes(bodyStyles, BODY_STYLE_KEYS.all) ? bodyStyles.split(',').map(String) : []
  );

  updateSelectedYearRange(
    year ? year.split(',').map(Number) : [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value]
  );

  updateUiSelectedYearRange(
    year ? year.split(',').map(Number) : [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value]
  );

  if (queryParams.pPlaterApproved) {
    addLifestyle(LIFESTYLE_PREFERENCES.pPlaterApproved);
  }

  if (queryParams.safeCarsForFamilies) {
    addLifestyle(LIFESTYLE_PREFERENCES.safeCarsForFamilies);
  }
};

export default hydrateFiltersStateFromUrl;
