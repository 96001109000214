import _ from 'lodash';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import SeoContext from '../context';

const Seo = () => <Tags {...useContext(SeoContext)} />;

const Tags = ({ title = '', additionalLinkTags = [], additionalMetaTags = [], jsonLinkedData = [] }) => {
  const router = useRouter();
  const getSeoTitle = () => {
    const isOnVehiclePage = router.route === '/[make]/[model]/[year]';
    const isOnResultsPage = router.route === '/results';
    if (isOnVehiclePage) {
      return title ? `How safe is the ${title}?` : 'How safe is this car?';
    }
    if (isOnResultsPage) {
      return `How Safe Is Your Car | Results`;
    }
    return title;
  };

  // @TODO - the following is a bandaid until we figure out why seomatic is failing us
  // seomatic is not setting robots correctly for production (always none)
  // so we need to manually override what’s being returned on the frontend
  const isProduction = process.env.NODE_ENV === 'production';
  const robotsContent = isProduction ? 'all' : 'none';
  const transformedAdditionalMetaTags = _.assign(
    additionalMetaTags,
    _.set(_.find(additionalMetaTags, { key: 'robots' }), 'data.content', robotsContent)
  );

  return (
    <Head>
      <title>{getSeoTitle()}</title>

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />

      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />

      {_.map(transformedAdditionalMetaTags, ({ key, data }) => (
        <meta data-testid={key} key={key} {...data} />
      ))}
      {_.map(additionalLinkTags, ({ key, data }) => (
        <link data-testid={key} key={key} {...data} />
      ))}
      {_.map(jsonLinkedData, ({ key, data }) => (
        <script
          data-testid={key}
          key={key}
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />
      ))}
    </Head>
  );
};

Tags.propTypes = {
  title: PropTypes.string,
  additionalLinkTags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.shape({
        rel: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }),
    })
  ),
  additionalMetaTags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
      }),
    })
  ),
  jsonLinkedData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.shape({}),
    })
  ),
};

export default Seo;
export { Tags };
