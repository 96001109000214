import { map, inRange, uniqBy } from 'lodash';
import { Hidden } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { ALERT_STATUSES } from '../../constants/statuses';
import { vehicleImagePropTypes } from '../../utils/get-vehicle-image/index.js';
import { H5 } from '../type';
import { URLS } from '../../constants';
import { PrimaryButton } from '../button';
import Alert from '../alert';
import Icon, { iconNames } from '../icons';
import MiniCartItem from '../mini-cart-item';
import { Panel } from '../panel';

import { CART_MAXIMUM, DISCOVERY_LINKS } from './constants';
import {
  AlertWrapper,
  StyledBody,
  StyledFooter,
  StyledH4,
  StyledHeader,
  StyledNavLink,
  StyledNavLinkText,
  StyledSmallTitle,
  StyledVehicleLinks,
} from './styles';

const LinksList = ({ links, onClose }) => {
  return map(links, (link, index) => (
    <StyledVehicleLinks key={`vehiclelink-${index}`}>
      <StyledNavLink tabIndex="0" onClick={onClose} href={link.href}>
        <H5 as="h2">
          <StyledNavLinkText>{link.linkText}</StyledNavLinkText>
          <StyledNavLinkText>
            <strong>{link.price}</strong>
          </StyledNavLinkText>
        </H5>
        <Hidden mdDown>
          <Icon name={iconNames.linkActive} />
        </Hidden>
      </StyledNavLink>
    </StyledVehicleLinks>
  ));
};

const Minicart = ({
  filterActions,
  isOpen = false,
  onClose,
  alertMessage = null,
  removeFromCompare,
  vehicleIds = [],
  vehicles = [],
  compareVehicleCount = 0,
}) => {
  const router = useRouter();
  const canCompareVehicles = inRange(compareVehicleCount, 2, CART_MAXIMUM + 1);

  return (
    <Panel anchor="right" ariaLabelledBy="compare-panel" isOpen={isOpen} onClose={onClose}>
      <StyledBody>
        <StyledHeader>
          <H5 id="compare-panel" tabIndex="-1" as="h1">
            Compare: {compareVehicleCount}
          </H5>
        </StyledHeader>
        {map(uniqBy(vehicles, 'redbookCode'), (vehicle) => (
          <MiniCartItem key={vehicle?.redbookCode} {...{ vehicle, removeFromCompare }} />
        ))}
        {compareVehicleCount === 0 && (
          <>
            <StyledH4 as="h2">No cars selected</StyledH4>
            <StyledSmallTitle>Browse safe cars for:</StyledSmallTitle>
            <LinksList {...{ filterActions, links: DISCOVERY_LINKS.NO_VEHICLE, onClose, router }} />
          </>
        )}
        {compareVehicleCount === 1 && (
          <LinksList {...{ filterActions, links: DISCOVERY_LINKS.SINGLE_VEHICLE, onClose, router }} />
        )}
        {compareVehicleCount > 1 && (
          <LinksList {...{ filterActions, links: DISCOVERY_LINKS.MULTIPLE_VEHICLES, onClose, router }} />
        )}
      </StyledBody>
      {compareVehicleCount > 0 && (
        <StyledFooter>
          {alertMessage && (
            <AlertWrapper>
              <Alert status={ALERT_STATUSES.error} content={alertMessage} />
            </AlertWrapper>
          )}
          <PrimaryButton
            disabled={!canCompareVehicles}
            href={`${URLS.COMPARE}?vehicles=${vehicleIds.join(',')}`}
            fullWidth
            disableRipple
          >
            Compare <Hidden mdDown>Cars</Hidden>
          </PrimaryButton>
        </StyledFooter>
      )}
    </Panel>
  );
};

Minicart.propTypes = {
  alertMessage: PropTypes.string,
  compareVehicleCount: PropTypes.number,
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
  vehicleIds: PropTypes.arrayOf(PropTypes.string),
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      ...vehicleImagePropTypes,
      name: PropTypes.string,
      ancapRating: PropTypes.number,
      priceRangeLow: PropTypes.number,
      priceRangeHigh: PropTypes.number,
      newPrice: PropTypes.number,
    })
  ),
};

export default Minicart;
