import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../../constants';

const StyledIcon = styled.svg`
  fill: ${COLORS.GREY_ELEVEN};
`;

const ExpandIcon = (props) => (
  <StyledIcon
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <polygon
      id="Icon/desktop/chevron/black/down"
      fillRule="nonzero"
      transform="translate(10.629994, 6.932726) rotate(-585.000000) translate(-10.629994, -6.932726) "
      points="15.4132465 2.14947305 3.11345301 2.14947305 3.11345301 -0.583814397 18.146534 -0.583814397 18.146534 14.4492666 15.4132465 14.4492666"
    />
  </StyledIcon>
);

export default ExpandIcon;
