import React from 'react';
import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import { spacing } from '@mui/system';

const StyledBox = styled(MuiBox)`
  ${spacing}
`;

const Box = ({ children, className, ...props }) => (
  <StyledBox {...props} className={className}>
    {children}
  </StyledBox>
);

export default Box;
