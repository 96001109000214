const BLACKLIST = [
  'isActive',
  'isSelected',
  'gridColumnGap',
  'isMobile',
  'useBackgroundImage',
  'url',
  'isShortened',
  'textAlignment',
  'hasFocalPoint',
  'focalPoint',
  'textColor',
  'backgroundColor',
  'isReverse',
  'ratingBy',
];
export const shouldForwardProp = (prop) => {
  return !BLACKLIST.includes(prop);
};
