import React from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { isNumber } from 'lodash';
import { spacing as muiSpacing } from '@mui/system';

import { GRID } from '../../constants';

import Box from './box';

const ROW_SPACING_STYLES = `
  margin-left: -${GRID.ROW_MARGIN};
  margin-right: -${GRID.ROW_MARGIN};
`;

const StyledRow = styled(Grid)`
  ${({ spacing }) => !isNumber(spacing) && ROW_SPACING_STYLES};

  ${muiSpacing}

  ${(props) =>
    props.fillSpace &&
    `
    ${Box} {
      flex: 1 1 auto;
      width: 100%;
    }
  `};

  &.MuiGrid-container {
    width: auto;
  }
`;

const Row = ({ className, children, spacing, ...props }) => (
  <StyledRow className={className} container spacing={spacing} {...props}>
    {children}
  </StyledRow>
);

export default Row;
