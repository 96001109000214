import React from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import _ from 'lodash';

import { COLORS, SPACE, BREAKPOINTS } from '../../constants';
import { ALERT_STATUSES } from '../../constants/statuses';
import { Small } from '../type/small';

export const AlertWrapper = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${COLORS.GREY_ELEVEN};
  display: flex;
  padding: ${SPACE.X0};

  @media (min-width: ${BREAKPOINTS.SM}px) {
    padding: 12px;
  }
`;

const StyledContent = styled.span`
  padding-left: ${SPACE.X0};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Alert = ({ status, content = null }) => {
  if (_.isNil(status) || !_.includes(ALERT_STATUSES, status)) return null;

  const { icon: Icon, backgroundColor, statusContent, statusText } = status;

  return (
    <AlertWrapper {...{ backgroundColor }}>
      <IconWrapper>
        <Icon aria-hidden="true" />
      </IconWrapper>
      <StyledContent>
        <Small>{statusContent || content}</Small>
      </StyledContent>
    </AlertWrapper>
  );
};

Alert.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  status: PropTypes.shape({
    icon: PropTypes.func,
    backgroundColor: PropTypes.string,
    statusContent: PropTypes.node,
    statusText: PropTypes.string,
  }).isRequired,
};

export default Alert;
