import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PrimaryButton } from '../button';
import RichText from '../rich-text';
import { H3, SectionHeadingStyles } from '../type';
import fallbackImage from '../../public/images/page-header-block.png';
import { BREAKPOINTS, SPACE } from '../../constants';
import { useGutterSpacing } from '../../hooks/use-gutter-spacing';
import { ContentBlockWrapper } from '../content-block-wrapper';

const StyledH3 = styled(H3)`
  margin-bottom: ${SPACE.X1};
`;

const StyledRichText = styled(RichText)`
  ${SectionHeadingStyles};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  &.MuiButton-root {
    margin-top: ${SPACE.X2};
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledBox = styled(Box)`
  width: 100%;
  z-index: 0;
`;

const SafetyFeatureShowcaseBlock = ({
  children = null,
  backgroundImage = {
    url: fallbackImage,
    description: 'Safety feature showcase - default image',
  },
  heading = null,
  body = null,
  safetyFeature = {
    slug: null,
    uri: null,
  },
}) => {
  const lgUp = useMediaQuery(`(min-width: ${BREAKPOINTS.LG}px)`);
  const mdUp = useMediaQuery(`(min-width: ${BREAKPOINTS.MD}px)`);

  return (
    <ContentBlockWrapper url={backgroundImage.url}>
      <StyledContainer maxWidth="xl">
        <Box pt={lgUp ? SPACE.X3 : SPACE.X2} pb={lgUp ? SPACE.X3 : '48px'}>
          <Grid container spacing={useGutterSpacing()}>
            <Grid item container alignItems="center" xs={7} sm={6}>
              <StyledBox justifyContent="flex-start">
                {heading && <StyledH3 as="h2">{heading}</StyledH3>}
                {body && <StyledRichText>{body}</StyledRichText>}
                {children}
              </StyledBox>
              {safetyFeature?.uri && (
                <StyledPrimaryButton fullWidth={!mdUp} href={safetyFeature.uri}>
                  See more
                </StyledPrimaryButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </ContentBlockWrapper>
  );
};

SafetyFeatureShowcaseBlock.query = `
  ... on contentMatrix_safetyFeatureShowcaseBlock_BlockType {
    id
    typeHandle
    heading
    body
    safetyFeature {
      slug
      uri
    }
    backgroundImage {
      title
      url
      kind
    }
  }
  `;

export default SafetyFeatureShowcaseBlock;

SafetyFeatureShowcaseBlock.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  heading: PropTypes.string,
  body: PropTypes.string,
  safetyFeature: PropTypes.shape({
    slug: PropTypes.string,
    uri: PropTypes.string,
  }),
};
