import _ from 'lodash';
import Grid from '@mui/material/Grid';
import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from '../breadcrumbs';

import {
  BreadcrumbsWrapper,
  ImageWrapper,
  Space1,
  StyledContainer,
  StyledImg,
  StyledHeading,
  StyledSectionHeading,
  StyledP,
  Wrapper,
} from './styles';

const alignItems = (textAlignment) => {
  switch (textAlignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

const PageHeaderBlock = ({
  image = null,
  category = '',
  heading = '',
  subHeading = null,
  breadcrumbs,
  backgroundImage = null,
  textAlignment = null,
  textColour = null,
}) => {
  const headingRef = React.useRef();

  React.useEffect(() => {
    if (headingRef.current) {
      headingRef.current.focus();
    }
  }, [headingRef]);

  return (
    <Wrapper>
      {_.get(backgroundImage, 'url') && (
        <ImageWrapper
          role="img"
          aria-label={backgroundImage?.title || 'Header Background Image'}
          {...{ textAlignment, ..._.pick(backgroundImage, 'url', 'focalPoint', 'hasFocalPoint') }}
        />
      )}
      <StyledContainer maxWidth="xl">
        <BreadcrumbsWrapper role="navigation" aria-label="You are here">
          <Breadcrumbs {...{ entry: breadcrumbs }} />
        </BreadcrumbsWrapper>
        <Grid container alignItems={alignItems(textAlignment)} direction="column">
          <Grid item xs={12} lg={8}>
            {image && <StyledImg src={image.url} alt={image.title} />}
            <Space1 />
            {!!category && (
              <>
                <StyledP>{category}</StyledP>
                <Space1 />
              </>
            )}
            <StyledHeading textColor={textColour} tabIndex="-1" ref={headingRef}>
              {heading}
            </StyledHeading>
            <Space1 />
            <StyledSectionHeading textColor={textColour}>{subHeading}</StyledSectionHeading>
          </Grid>
        </Grid>
      </StyledContainer>
    </Wrapper>
  );
};

PageHeaderBlock.query = `
  ... on pageHeaderMatrix_pageHeaderBlock_BlockType {
    id
    typeHandle
    image {
      title
      url
      kind
    }
    preHeading
    heading
    subHeading
    textAlignment
    textColour
    backgroundImage {
      title
      url
      kind
      ... on AssetInterface {
        hasFocalPoint
        focalPoint
      }
    }
  }
`;

PageHeaderBlock.propTypes = {
  image: PropTypes.PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    kind: PropTypes.string,
  }),
  backgroundImage: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    kind: PropTypes.string,
    hasFocalPoint: PropTypes.bool,
    focalPoint: PropTypes.arrayOf(PropTypes.number),
  }),
  category: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  breadcrumbs: PropTypes.shape({
    title: PropTypes.string,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }).isRequired,
  textAlignment: PropTypes.string,
  textColour: PropTypes.string,
};

export default PageHeaderBlock;
