import styled from '@emotion/styled';

import { COLORS, SPACE } from '../../constants';

const RemoveLink = styled.a`
  align-items: center;
  color: ${COLORS.BLACK};
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;

  svg {
    margin-right: 6px;
    height: 12px;
    width: 12px;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORS.BRAND_BLUE_SIX};

    svg {
      path {
        fill: ${COLORS.BRAND_BLUE_SIX};
      }
    }
  }
`;

const StyledVehicleCard = styled.div`
  border-bottom: 1px solid ${COLORS.GREY_FOUR};
  display: flex;
  padding: ${SPACE.X1} 0;
  height: auto;

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export { RemoveLink, StyledVehicleCard };
