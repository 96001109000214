import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const ContentBlockWrapper = styled('div', { shouldForwardProp })`
  padding-bottom: 0;
  padding-top: ${SPACE.X2};
  width: 100%;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding-bottom: ${SPACE.X3};
    padding-top: 0;
  }

  ${({ url = null, useBackgroundImage = false }) =>
    useBackgroundImage &&
    url &&
    css`
      background-image: url(${url});
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    `};
`;

export { ContentBlockWrapper };
