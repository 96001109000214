import _ from 'lodash';
import PropTypes from 'prop-types';

export const GRAPHQL_IMAGE_FIELDS = `
  image: redbookImage,
  imageOverride {
    url
  },
`;

const isEveryValueANonEmptyString = (values = []) => _.every(values, (value) => !_.isEmpty(value) && _.isString(value));

const overrideImageUrlDomain = (url) => {
  if (
    !isEveryValueANonEmptyString([
      process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_FROM,
      process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_TO,
      url,
    ])
  ) {
    return url;
  }

  return _.replace(url, process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_FROM, process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_TO);
};

export const getVehicleImage = (vehicle) => {
  const matchingPath = _.find(['imageOverride[0].url', 'image.url', 'image'], (path) => {
    const value = _.get(vehicle, path);
    return !_.isEmpty(value) && _.isString(value);
  });

  return {
    src: overrideImageUrlDomain(_.get(vehicle, matchingPath)),
    alt: `${_.get(vehicle, 'year', '')}
          ${_.get(vehicle, 'makeDescription', '')}
          ${_.get(vehicle, 'modelDescription', '')}`,
  };
};

export const getVehicleImages = (vehicles) => {
  return _.map(vehicles, (vehicle) => {
    const vehicleImage = getVehicleImage(vehicle);
    return {
      ...vehicle,
      image: vehicleImage?.src,
      alt: vehicleImage?.alt,
    };
  });
};

export const vehicleImagePropTypes = {
  image: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ),
    PropTypes.string,
  ]),
  imageOverride: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
};
