import React from 'react';
import styled from '@emotion/styled';
import MuiContainer from '@mui/material/Container';
import { spacing } from '@mui/system';

import { GRID } from '../../constants';

const StyledMuiContainer = styled(MuiContainer)`
  ${spacing}
`;

const Container = ({ className, maxWidth, children, ...props }) => {
  const containerMaxWidth = maxWidth || GRID.CONTAINER_MAX_WIDTH;

  return (
    <StyledMuiContainer className={className} maxWidth={containerMaxWidth} {...props}>
      {children}
    </StyledMuiContainer>
  );
};

export default Container;
