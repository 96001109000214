import React from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import _ from 'lodash';

import { COLORS, SPACE } from '../../constants';
import { Container, Row, Col } from '../grid';
import { H2 } from '../type';
import RichText from '../rich-text';
import craftBackend from '../../utils/craft-backend-api';

import ContactForm from './contact-form';

const Wrapper = styled.div`
  background-color: ${COLORS.GREY_ONE};
  color: ${COLORS.GREY_ELEVEN};
  padding: ${SPACE.XL} 0;
`;

const ContentCol = styled(Col)`
  text-align: center;
`;

async function defaultHandleSubmit({ ...formValues }, { setStatus, setSubmitting, setErrors }) {
  try {
    await craftBackend.submitForm('/', formValues);
    setStatus('success');
    setSubmitting(false);
  } catch (error) {
    setErrors({
      general: _.isEmpty(error.fieldErrors) ? error.message : null,
      ...error.fieldErrors,
    });
    setSubmitting(false);
  }
}

const ContactFormBlock = ({
  body = null,
  subject = null,
  heading = null,
  successMessage,
  handleSubmit = defaultHandleSubmit,
  isLoading = false,
}) => {
  return (
    <Wrapper>
      <Container>
        <Row wrap="wrap">
          <ContentCol width={{ xs: 12, lg: 6 }} offset={[0, 0, 0, 3 / 12]}>
            {heading && <H2>{heading}</H2>}
            {body && <RichText keepMargins>{body}</RichText>}
          </ContentCol>
        </Row>
        <ContactForm
          subject={subject}
          successMessage={successMessage}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </Container>
    </Wrapper>
  );
};

ContactFormBlock.query = `
  ... on contentMatrix_contactFormBlock_BlockType {
    id
    typeHandle
    heading
    body
    subject
    successMessage
  }
  `;

ContactFormBlock.propTypes = {
  body: PropTypes.string,
  handleSubmit: PropTypes.func,
  heading: PropTypes.string,
  subject: PropTypes.string,
  successMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ContactFormBlock;
