import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Image from 'next/image';

import TacLogo from '../../assets/logos/tac-logo.svg';
import HsiycTacLogo from '../../assets/logos/hsiyc-tac-horizontal-pos-cmyk.svg';
import { BREAKPOINTS } from '../../constants';

const StyledLogo = styled(Image)`
  object-fit: contain;
  width: ${(props) => (props.src === TacLogo ? '50px' : '184px')};
  height: 100%;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    width: ${(props) => (props.src === TacLogo ? '64px' : '184px')};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: ${(props) => (props.src === TacLogo ? '83px' : '276px')};
  }
`;

const Logos = ({ isHomePage = false }) =>
  isHomePage ? (
    <StyledLogo src={TacLogo} alt="TAC Logo" />
  ) : (
    <StyledLogo src={HsiycTacLogo} alt="How Safe Is Your Car? Logo" />
  );

Logos.propTypes = {
  isHomePage: PropTypes.bool,
};

export default Logos;
