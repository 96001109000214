import React from 'react';

import BaseContentMatrix, { basePropTypes } from '../base-content-matrix';
import { buildContentMatrixElementQueryString } from '../../utils/content-matrix';
import PageHeaderBlock from '../page-header-block';

const CONTENT_MATRIX_MAPPING = {
  pageHeaderBlock: PageHeaderBlock,
};

const PageHeaderMatrix = (props) => <BaseContentMatrix {...{ ...props, elementMapping: CONTENT_MATRIX_MAPPING }} />;

PageHeaderMatrix.propTypes = basePropTypes;

PageHeaderMatrix.query = `
  pageHeaderMatrix {
    typeName: __typename
    ${buildContentMatrixElementQueryString(CONTENT_MATRIX_MAPPING)}
  }
`;

export default PageHeaderMatrix;
export { CONTENT_MATRIX_MAPPING };
